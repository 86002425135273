<template>
    <div class="w-full h-full flex items-center justify-center ">
        Sign out!
    </div>
</template>

<script>

import AuthMixins from '@/mixins/AuthMixins';

export default {
    data() {
        return {
            
        }
    },
    
    mixins: [
        AuthMixins,
    ],
    
    mounted(){
        console.log('signing out...');
        this.signOut();
    }
    
}
</script>
